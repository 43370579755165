import React, { useState, useEffect } from 'react';
import { AppstoreOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from './Nav.module.css';
import Logo from '../img/InterStand_LOGO.png'
import { googleLogout } from '@react-oauth/google';
import { useSelector, useDispatch } from "react-redux";
import { loggedIn, loggedOut } from "../store/loginSlice";
import { resetSubscription }
  from '../store/subscriptionSlice';

const items = [
  {
    label: "",
    key: 'home',
    icon: <img src={Logo} alt="logo" className={styles.logo} />,
    // className 可以添加到这里

  },

  


  {
    label: 'Pricing',
    key: 'subscription',
    // disabled: true,
  },
  {
    label: 'Resources',
    key: 'resources',
    children: [
      {
        label: 'Tutorials',
        key: 'instruction',
        // disabled: true,
      },
      // {
      //   label: 'User Stories',
      //   key: 'setting:2',
      // },
    ]
  },
  {
    label: 'Dashboard',
    key: 'dashboard',

  },
  {
    label: 'Contact Us',
    key: 'contact-us',

  },
  {
    label: 'About',
    key: 'about',
    children: [
      {
        label: 'Privacy Policy',
        key: 'private-policy',
      },
      // {
      //   label: 'User Stories',
      //   key: 'setting:2',
      // },
    ]
  },

  {
    // label: 'Products',
    // key: 'product',
    label: 'Blogs',
    key: 'blogs',

    icon: <AppstoreOutlined />,
    disabled: true,
  },
];

// 组件 
const NavBar = ({ children }) => {
  // 光标状态
  const [current, setCurrent] = useState([]);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    // 检查localStorage中的jwt token
    const jwtToken = localStorage.getItem('jwt');
    // token 发生改变的时候 发送token到window （要考虑token 过期的问题）
    // 根据login, logout 的状态来改变 
    const dataToTranslatorLogin = {
      source: "smartLin-translator__web",
      action: 'login',
      data: {
        token: jwtToken,
      }
    }
    const dataToTranslatorLogOut = {
      source: "smartLin-translator__web",
      action: 'loginOut',
    }
    if (jwtToken) {
      dispatch(loggedIn(jwtToken));
      window.postMessage(dataToTranslatorLogin, "*");
      // console.log("登陆后发送token 到window")
    } else {
      dispatch(loggedOut());
      window.postMessage(dataToTranslatorLogOut, "*");
      // console.log("登出后发送token 到window")
    }
  }, [dispatch, isLoggedIn]);

  const handleLogout = () => {
    localStorage.removeItem('jwt');// 需要增加逻辑， jwt 被删除， 登出
    googleLogout();// 应该清除了Goolge的会话?
    dispatch(loggedOut());
    dispatch(resetSubscription())
    // redirect 到首页
    navigate('/home')

  };

  // 使用 navigate 进行跳转
  const navigate = useNavigate();
  const onClick = (e) => {
    setCurrent([e.key]);
    navigate(`/${e.key}`);
  };
  return (
    <div className={styles["nav-box"]}>
      <Menu onClick={onClick} selectedKeys={current} mode="horizontal"
        items={items} className={styles["nav-menu"]} />
      <div>
        {!isLoggedIn ? (
          <button className={styles["button"]} onClick={() =>
            navigate('/dashboard')}>Log in</button>

        ) : (
          <button className={styles["button"]}
            onClick={handleLogout}>Log Out</button>
        )}

      </div>
    </div>
  )
};

export default NavBar;