import styles from './index.module.css';
import whatsAppCode from '../../img/whatsapp.jpg';
import { DiscordFilled } from '@ant-design/icons';


const ContactPage = () => {

    return (
        
        <div className={styles['general-container']} >

            <div className={styles['info-container']} >
                <div className={styles['temp-notes']}>
                Hey there! Welcome to the InterStand Community! 
                If you're keen to explore our Apps & Google Extension, 
                reach out to us on WhatsApp or Discord! 
                Excited for you to join us. 
                </div>
                <div className={styles['temp-notes']}> 
                Please email us at nexaed2023@gmail.com 
                if you have any questions! 
                </div>

                <div className={styles['pic-box']}>
                    <img className={styles['whatsapp-pic']}
                        src={whatsAppCode}  alt="whatsapp-code" />
                    <a href="https://discord.gg/WmSGcQMyFG" 
                        target="_blank" rel="noopener noreferrer">
                            <DiscordFilled 
                            title="Join Our Discord Community and 
                            Contact Us to Get 50 Free usage tokens!!"
                            className={styles['icon']}/>
                    </a>
                
                </div>
            </div>
        </div>
    )

}


export default ContactPage