import styles from './Index.module.css';
// import TopPostBadge from '../../img/top-post-badge.svg'
import ChromeLogo from '../../img/Google_Chrome-Logo.png'
import OtherAppPic from '../../img/otherApp.png'
import InstructionVideo from '../../video/instruction.mp4'
import TranslationGIF from '../../video/trans_gif.gif'
import ExeFile from '../../softwares/InterStand.exe'
import { useState, useEffect, useRef } from 'react';
import { DiscordFilled, FacebookFilled, WindowsOutlined, 
    GlobalOutlined, BarChartOutlined, BookOutlined, 
    TeamOutlined, SearchOutlined, HeartOutlined} from '@ant-design/icons';


const HomePage = () => {

    const [texts] = useState(['Working', 'Learning', 'Communicate',
        'Writing', 'Everything']);
    const textsIndex = useRef(0);
    const [text, setText] = useState('');

    //useEffect 首页挂载的时候执行
    useEffect(() => {
        const incrementText = (originalText) => {
            let index = 0;
            const timer = setInterval(() => {
                // slice 截取
                const partialText = originalText.slice(0, index + 1);
                setText(partialText);
                if (index === originalText.length - 1) {
                    clearInterval(timer);
                    setTimeout(() => {
                        decrementText(originalText);
                    }, 1200); // 停顿1.2秒后再执行 decrementText
                }
                index++
            }, 100)
        }

        const decrementText = (originalText) => {
            let index = originalText.length;
            const timer = setInterval(() => {
                // 注意这里是正向递减index
                index--;
                const partialText = originalText.slice(0, index);
                setText(partialText);
                if (index === 0) {
                    clearInterval(timer)
                    // 更新textsIndex以指向下一个文本
                    textsIndex.current = textsIndex.current === 
                    texts.length - 1 ? 0 : textsIndex.current + 1;
                    // 给下一轮动画一点时间启动
                    setTimeout(() => {
                        incrementText(texts[textsIndex.current]);
                    }, 1200); // 继续下一个文本的展示前稍作停顿
                }
            }, 60);
        }
        incrementText(texts[textsIndex.current])
    }, [texts])



    return (
        // 整个container 
        <div>
            <div className={styles['page-one']}>
                <div className={styles.container}>
                    {/* 1. 区域1 --- 文字title 和 视频区域 */}
                    <div className={styles['area-top']}>
                        {/* 1.1 文字部分 */}
                        <div className={styles['top-box-left']}>
                            <div className={styles['top-left-title']}>
                                {/* 这是span 和 div 一样吗 */}
                                
                                <div className={styles.text}>Instant Translate & Explain with One Click in Any APP </div>
                                <div className=''>
                                    {/* In <span className={styles["blinking-text"]}>
                                        {text}</span>
                                    <span className={styles["blinking-line"]}>|</span> */}
                                </div>
                                <div className={styles.text}>with </div>
                                <div className={styles.textSpecial}>
                                    InterStand </div>
                                

                            </div>
                            <div className={styles['normal-text-box']}>
                            Unlock Instant Understanding: One Click, Infinite Possibilities — Translate, Explain Text, and Access More Features Anywhere, in Any Application on Your Computer with the Power of InterStand AI!
                            </div>
                            <div className={styles['button-box-p1']}>

                                <a href="https://discord.gg/WmSGcQMyFG" 
                                target="_blank" rel="noopener noreferrer" download>
                                    <DiscordFilled 
                                    title="Join Our Discord Community !!"
                                    className={styles['icon']}/>
                                </a>
                                
                                <a href="https://www.facebook.com/InterStand2024/" 
                                target="_blank" rel="noopener noreferrer">
                                    <FacebookFilled 
                                    title="Join Our Facebook Community !!"
                                    className={styles['icon']}/>
                                </a> 
                            </div>
                        </div>

                        {/* 1.2 --- 首页右边的视频区域 */}
                        <div className={`${styles['top-box-right']}
                                        ${styles['top-right-hero']}`}>
                            <img className={styles["top-right-hero"]} src={TranslationGIF} 
                                alt="translation gif" loop autoplay muted playsinline />
                            
                        </div>
                    </div>

                    {/* 2. 第一页的下边栏 --- 目前是有三个 下载按钮 */}
                    <div className={styles['area-bottom']}>
                        {/* windows 下载按钮 */}
                        <a href={ExeFile}
                            target="_blank" rel="noopener noreferrer">
                                <button className={styles['button']}>
                                    Download for Windows
                                    <WindowsOutlined 
                                    title="Download the Windows version"
                                    className={styles['icon']}/>    
                                </button>    
                        </a>

                        {/* chrome 下载按钮 */}
                        <a 
    href="https://chromewebstore.google.com/detail/mdefchfdgegpajbaiegdlpggdokdgnii?hl=en&authuser=0" 
                                target="_blank" rel="noopener noreferrer">
                            <button className={styles['button']}>
                                Add to Chrome
                                <img src={ChromeLogo} alt="Chrome Logo" 
                                className={styles['chrome-logo']} />
                            </button>
                        </a>
                        <button className={`
                                        ${styles['notAvailable']}`}>
                            macOS Version Coming Soon!
                        </button>
                        <button className={`
                                        ${styles['notAvailable']}`}>
                            Mobile Version Coming Soon!
                        </button>

                       

                    </div>
                    
                </div>
            </div>
            
            {/* page 2 --- integration with other apps  */}
            <div className={styles['page-two']}>
                <div className={styles.container}>
                    <div className={styles['title-area']}>
                        <div className={styles['title-text']}>
                            Integrated with Other Apps

                        </div>
                        <div className={styles['subTitle-text']}>
                        You can utilize InterStand in any application on your computer, 
                        provided that you are able to select the text with your mouse. 
                        </div>
                    </div>

                    <div className={styles['mainPic-area']}>
                        <img src={OtherAppPic} alt="Other Apps" 
                                    className={styles['main-pic']} />

                    </div>
                </div>
            </div>


            {/* page three --- Instruction  */}
            <div className={styles['page-three']}>
                <div className={styles.container}>
                    {/* 2. 区域2  */}
                    <div className={styles['area-top']}>
                        {/* 2.1视频部分 */}
                        <div className={`${styles['top-box-pic']}
                                        `}>
                            <video className={styles["top-right-hero"]} controls>
                                <source src={InstructionVideo} type="video/mp4" />
                            </video>
                        </div>


                        {/* 2.2 文字部分 */}
                        <div className={styles['top-box-text']}>
                            <div className={styles['top-right-texts']}>
                                {/* 这是span 和 div 一样吗 */}
                    
                                <div className={styles.text}>Easy to Use for Achieving the Best Results in Translation & Text Comprehension</div>
                                {/* <div className={styles.text}>With </div> */}
                                {/* <div className={styles.textSpecial}>
                                    InterStand </div> */}
                            </div>
                            {/* <div className={styles['normal-text-box']}>
                            InterStand products go beyond translation to 
                            swiftly and effectively deepen your 
                            understanding of languages and emerging 
                            concepts in an AI-driven world, 
                            enhancing your ability to keep pace with
                            technological advancement.
                            </div> */}
                            <div className={styles['button-box']}>
                            <a 
 href={ExeFile}
                            target="_blank" rel="noopener noreferrer" download>
                                <button className={styles['button']}>
                                    Download for Windows
                                    <WindowsOutlined 
                                    title="Download the Windows version"
                                    className={styles['icon']}/>  
                                    
                                    
                                </button>
                            </a>
                                <a href="https://discord.gg/WmSGcQMyFG" 
                                target="_blank" rel="noopener noreferrer">
                                    <DiscordFilled 
                                    title="Join Our Discord Community and 
                                    Contact Us to Get 50 Free usage tokens!!"
                                    className={styles['icon']}/>
                                </a>
                                
                                <a href="https://www.facebook.com/InterStand2024/" 
                                target="_blank" rel="noopener noreferrer">
                                    <FacebookFilled 
                                    title="Join Facebook Community"
                                    className={styles['icon']}/>
                                </a>
                            </div>
                            <a 
 href="https://chromewebstore.google.com/detail/mdefchfdgegpajbaiegdlpggdokdgnii?hl=en&authuser=0" 
                            target="_blank" rel="noopener noreferrer">
                            <button className={styles['button']}>
                                Add to Chrome 
                                <img src={ChromeLogo} alt="Chrome Logo" 
                                className={styles['chrome-logo']} />
                            </button>
                            </a>
                        </div>

                        
                    </div>
                    
                </div>
            </div>

            {/* page 4 --- integration with other apps  */}
            <div className={styles['page-two']}>
                <div className={styles.container}>
                    <div className={styles['title-area']}>
                        <div className={styles['title-text']}>
                            Achieve Success with InterStand 
                        </div>
                        <div className={styles['subTitle-text']}>
                            InterStand helps you understand complex texts through one-click translation
                            and explanation, 
                            enhancing your learning and work efficiency, and aiding your success. 
                        </div>
                    </div>

                    <div className={styles['main-content']}>
                        <div  className={styles['left-blocks']}>
                            <div className={styles['single-block']} >
                                <div className={styles['icon-box']}>
                                    <div className={styles['outlined-icon']}>
                                        <GlobalOutlined />
                                    </div>
                                </div>

                                <div className={styles['text-box']}>
                                    <div className={styles['block-title']}>
                                        Global Team
                                    </div>
                                    <div className={styles['block-content']}>
                                        InterStand seamlessly integrates with CRM software and group 
                                        communication tools to empower your success in a global team 
                                        environment enriched by diverse cultures.
                                    </div>

                                </div>

                            </div>
                            <div className={styles['single-block']} >
                                <div className={styles['icon-box']}>
                                    <div className={styles['outlined-icon']}>
                                        <BarChartOutlined />
                                    </div>
                                </div>
                                <div className={styles['text-box']}>
                                    <div className={styles['block-title']}>
                                        Analysizing
                                    </div>
                                    <div className={styles['block-content']}>
                                        In addition to translation and text explanation, 
                                        InterStand integrates with software like Excel, 
                                        allowing you to use InterStand for data analysis and interpretation. 
                                        This is another area where InterStand is committed to expanding its 
                                        capabilities beyond language learning and knowledge comprehension.
                                    </div>

                                </div>
                                
                            </div>
                            <div className={styles['single-block']} >
                                <div className={styles['icon-box']}>
                                    <div className={styles['outlined-icon']}>
                                        <BookOutlined />
                                    </div>
                                </div>
                                <div className={styles['text-box']}>
                                    <div className={styles['block-title']}>
                                        Learning & Researching
                                    </div>
                                    <div className={styles['block-content']}>
                                        InterStand stands out as a superior learning tool,
                                        focusing on helping you identify your questions through reading and find answers, 
                                        rather than just offering full-text translations. 
                                        Our team of educational psychologists is committed to developing 
                                        more effective and professional AI learning tools, significantly
                                        boosting your learning potential.
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div  className={styles['right-blocks']}>
                            <div className={styles['single-block']} >
                                <div className={styles['icon-box']}>
                                    <div className={styles['outlined-icon']}>
                                        <TeamOutlined />
                                    </div>
                                </div>
                                <div className={styles['text-box']}>
                                    <div className={styles['block-title']}>
                                        Communication
                                    </div>
                                    <div className={styles['block-content']}>
                                        InterStand assists users in communicating with friends who speak 
                                        other languages by offering one-click translation and explanations 
                                        (learning mode). It helps users understand colloquialisms and complex 
                                        vocabulary from various countries and cultures, 
                                        thereby bridging cultural and linguistic communication barriers.
                                    </div>

                                </div>

                            </div>
                            <div className={styles['single-block']} >
                                <div className={styles['icon-box']}>
                                    <div className={styles['outlined-icon']}>
                                        <SearchOutlined />
                                    </div>
                                </div>
                                <div className={styles['text-box']}>
                                    <div className={styles['block-title']}>
                                        Discovery
                                    </div>
                                    <div className={styles['block-content']}>
                                        InterStand brings you closer to the world. Whether it's global news or complex PDF books,
                                        you can achieve smooth reading and in-depth analysis of key points with a single click using InterStand.
                                    </div>

                                </div>
                                
                            </div>
                            <div className={styles['single-block']} >
                                <div className={styles['icon-box']}>
                                    <div className={styles['outlined-icon']}>
                                        <HeartOutlined />
                                    </div>
                                </div>
                                <div className={styles['text-box']}>
                                    <div className={styles['block-title']}>
                                        Customer Success
                                    </div>
                                    <div className={styles['block-content']}>
                                    InterStand can enhance your understanding of customer needs, 
                                    and users can leverage InterStand to refine their responses 
                                    in any required language.
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default HomePage